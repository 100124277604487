// Base Settings
html {
    font-size: $fz-base;
}

body {
    position: relative;
    height: 100vh;
    font-family: $default-font;
    font-size: 1.4rem;
    &.active {
        overflow-y: hidden;
    }
}

