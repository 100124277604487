.img-mt {
  margin-top: 20px;
}
.training__list {
  margin-top: 30px;
  li {
    margin-left: 40px;
    font-size: 14px;
    line-height: 16px;
    color: $primary;
    position: relative;
    &::before {
      content: "";
      width: 24px;
      height: 23px;
      background: url(../img/training/spisok.png);

      position: absolute;
      top: -4px;
      left: -30px;
    }
  }
  & > li + li {
    margin-top: 15px;
  }
}
.quest__cont {
    margin-left: 30px;
    margin-top: 20px;
    .section__text-sm {
        margin-top: 5px;
    }
}
.quest__title {
    margin-top: 10px;
    font-size: 18px;
    color: steelblue;
    line-height: 20px;
}