.footer1 {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}
.footer__bg {
    background-color: #44545d;
    margin-top: 20px;
}
.footer__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
}




.social__menu {
    display: flex;
    &>li+li {
        margin-left: 15px;
    }
}

.social__link {
    svg {
        transition: all .2s ease;
    }
    &:hover svg{
        fill: $pink;
    }
}


.copyright {
    margin-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    line-height: 16px;
    font-size: 13px;
    color: #666;
    margin-bottom: auto;
}
@include mq('sm') {
    .footer__wrap {
        flex-direction: column-reverse;
    }
    .social__menu {
        margin-top: 10px;
    }
}
@include mq('xs') {
    .copyright {
        text-align: center;
        flex-direction: column;
    }
    .right__side {
        margin-top: 10px;
    }
}