.friend__wrapper {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
}
.img__birth {
    margin-right: 20px;
}
@include mq('sm') {
    .friend__wrapper {
        flex-direction: column;
        margin-top: 20px;
        justify-content: center;
        align-items: center;
    }
}

.birth__conta {
    margin-bottom: 100px;
}
.friend__conta {
    margin-bottom: 73px;
}
@include mq('sm') {
    .birth__conta {
        margin-bottom: 0px;
    }
    .friend__conta {
        margin-bottom: 0px;
    }
}