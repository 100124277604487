.italic {
    font-style: italic;
}
.underline {
    text-decoration: underline;
}
.keratin__border {
    border: 3px dotted #EE1;
    margin: 15px;
    padding: 15px;
    background-color: #fffff4;
}
.mt0 {
    margin-top: 0!important;
}
.fw700 {
    font-weight: 700;
    text-decoration: none;
}