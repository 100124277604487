.table-price {
    background: $primary;
    color: $white;
}
.table{
    margin-top: 5px;
    font-size: 16px;
}
.table-pr {
    margin-top: 20px;
}
.table__row {
    color: $white;
    display: flex;
    max-width: 600px;
    margin: 0 auto;
    .table__head {
        width: 50%;
        border: 1px solid #ececec;
        padding-left: 6px;
        padding: 8px;
    }
    .table__cont {
        width: 50%;
        border: 1px solid #ececec;
        padding-left: 6px;
        padding: 8px;
    }
}
.table__row-bl {
    color: $primary;
}
.pink_bold {
    font-weight: 700;
    color: $pink;
}
.price__dec {
    margin-top: 10px;
    text-align: center;
    padding-bottom: 30px;
}
@include mq('sm') {
    .section__title {
        font-size: 33px;
        line-height: 36px;
    }
    .section__subtitle {
        font-size: 16px;
        line-height: 18px;
    }
}
@media (max-width:370px) {
    .table {
        font-size: 13px;
    }
    .table-pr {
        font-size: 13px;
    }
}
