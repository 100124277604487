
  #menu {
    display: none;
      margin: 10px;
      z-index: 100;
      margin-bottom: 0;
  }
  @include mq('xs') {
    #menu {
        margin: 5px;
    }
  }
  #menu-burger {
      font-size: 2em;
      margin: auto;
      transition-duration: 400ms;
      transform-origin: center;
  }
  #burger-container {
    position: relative;
    width: 35px;
    height: 30px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .burger-line {
    background-color: #fff;
    height: 10%;
    width: 100%;
    border-radius: 75px;
    transition-duration: 400ms;
    transform-origin: left;
  }
  
  .option__main {
      margin-bottom: 20px;
    background-color: $pink;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    display: flex;
      flex-direction: column;
      text-align: center;
    transition-duration: 200ms;
      cursor: pointer;
      &:hover {
        background-color: lighten($color: $pink, $amount: 10%)
      }
  }
  .option__main:hover {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  }
  .option__main .option-name {
      padding: 10px 0 5px 0;
  }
  .option__main svg {
      padding: 10px 0 0 0;
    height: 60%;
    margin: auto;
  }
  
  #option-container {
      padding: 0px  20px 0 20px;
      position: absolute;
      display: flex;
      flex-direction: column;
      transition-duration: 400ms;
      background-color: var(--background-color);
  }
  
  .option__large {
      width: 200px;
      height: 40px;
      line-height: 40px;
      flex-direction: row;
  }
  .option__large .option-name {
      padding: 0 25px 0 40px;
  }
  #back-timeline svg,#full-screen svg {
      padding: 0 0 0 25px;
      height: 1em;
  }
  
      /* JS */
  .visible {
      left: 0px;
  }
  .invisible {
      left: -220px;
  }

  .burger__link {
    color: $white;
    
  }
.burger__bg {
  &.active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: -1;
  }
  
}
@include mq('sm') {
  #menu {
      display: flex;
  }
}