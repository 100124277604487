.hero {
    background: url(../img/back_main.jpg) no-repeat;
    background-position: center;
    background-size: contain;
    min-height: 546px;
}
@media (max-width: 1500px) {
    .hero {
        background-size: 100%;
        min-height: 513px;
    }
    
}
@media (max-width: 991px) {
    .hero {
        background-size: 100%;
        min-height: 416px;
    }
    
}
@media (max-width: 767px) {
    .hero {
        background-size: cover;
        background-position: right;
        min-height: 329px;
    }
}
@include mq('xs') {
    .hero {
        background-size: cover;
        background-position-x: -166px;
        min-height: 298px;
    }
}
@media (max-width: 416px) {
    .hero {
        background-size: cover;
        background-position-x: -274px;
    min-height: 275px;
    }
}

.post__bg {
    overflow: hidden;
    max-width: 308px;
    height: 220px;
    background: url(../img/price.jpg) no-repeat;
}
.post__bg--friend {
    background: url(../img/friend.jpg) no-repeat;
}
.post__bg--birth {
    background: url(../img/birth.jpg) no-repeat;
}
.post__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.post__item {
    margin-top: 15px;
    cursor: pointer;
    &:hover .post__content {
        transform: translate(0,0); 
    }
}


.post__content {
    padding: 0 15px;
    color: $white;
    width: 100%;
    height: 100%;
    background: rgba(255,75,189,0.9);

    transform: translate(0,160px);
    transition: all .4s ease;
    
    .post__title {
        font-size: 20px;
        font-weight: 300;
        line-height: 22px;
        color: $white;
        padding-top: 10px;
    }
    .post__desc {
        margin-top: 30px;
        font-size: 14px;
        line-height: 18px;
    }
    .post__link {
       display: inline-block;
       margin-top: 20px;
       font-size: 14px;
       line-height: 18px;
       text-decoration: underline;
       color: $white;
       &:hover {
           text-decoration: none;
       }
    }
}

