a[data-fancybox] img {
    cursor: zoom-in;
  }
  
  .fancybox__container {
    --fancybox-bg: rgba(17, 6, 25, 0.85);
  }
  
  .fancybox__container  {
    .fancybox__content {
      padding: 1rem;
      border-radius: 6px;
      color: #374151;
      background: #fff;
      box-shadow: 0 8px 23px rgba(0, 0, 0, 50%);
    }
    
  }
  
  .fancybox__content{
    & > .carousel__button.is-close {
      top: 0;
      right: -38px;
    }
  }
  
  .fancybox__caption {
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    padding-bottom: 0.25rem;
    width: 100%;
    border-top: 1px solid #ccc;
    font-size: 1rem;
    line-height: 1.5rem;
  
    /* Prevent opacity change when dragging up/down */
    --fancybox-opacity: 1;
  }
.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  a {
    margin-top: 4px;
  }
}
.gallery__size {
  width: 200px;
  height: 223px;
}
