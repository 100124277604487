.fancy__cont-sertif {
    max-width: 773px;
    margin: 0 auto;
}
.sertif__size {
    width: 280px;
    height: 200px;
  }
  .sertif__size1 {
    width: 200px;
    height: 280px;
  }