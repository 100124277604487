.service__item-cont {
  margin-top: 10px;
  max-width: 460px;
  height: 380px;
  overflow: hidden;
  border: 1px solid #e9e3e3;
}
.service__item {
  transform: translateY(0);
  transition: all 0.2s ease;
  &:hover {
    transform: translateY(-50px);
  }
}
.service__content {
  padding: 10px;
}
.service__menu {
  display: flex;
  flex-wrap: wrap;
 justify-content: space-around;
}
.service__item-title {
  font-size: 26px;
  line-height: 28px;
  color: $primary;
  font-weight: 300;
}
.service__item-desc {
  font-size: 13px;
  line-height: 16px;
  color: $primary;
  margin-top: 10px;
}
.content__link {
  color: $pink;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
.service__item-title {
    position: relative;
  &::before {
    content: "от 2000";
    width: 92px;
    height: 92px;
    text-align: center;
    color: $white;
    padding-top: 11px;

    position: absolute;
    background: url(../img/price.png) no-repeat;
    background-size: contain;
    top: -60px;
    right: 0;
  }
}
.desc-mb {
  padding-bottom: 10px;
}
@include mq('md') {
  .service__item-cont {
    max-width: 390px;
    height: auto;
  }
  .service__item {
    &:hover {
      transform: translateY(0px);
    }
  }
}
@media (max-width:831px) {
  .service__item-cont {
    max-width: 367px;
  }
}