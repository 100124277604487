.header {
    padding-bottom: 20px;
    padding-top: 10px;
    border-bottom: 1px solid #e7e7e7;
    position: fixed;
    width: 100%;
    background-color: $white;
    z-index: 1000;
}
.img__logo {
    width: 175px;
    height: 51px;
    z-index: -2;
}
.header__logo {
    max-width: 100%;
    width: 100%;
}
.header__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header__title {
    text-align: right;
    color: $primary;
    font-size: 26px;
    line-height: 28px;
    font-weight: 300;
}

.phone__link {
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    color: $primary;
}

.phone {
    flex: 1;
}
.nav__menu {
    flex: 2;
}
.nav__list {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    &>li+li {
        margin-left: 10px;
    }
    .nav__link {
        font-size: 14px;
        line-height: 16px;
        color: $primary;
        transition: all .4s ease;
        &.active {
            color: $pink;
            font-weight: 700;
        }
        &:hover{
            color: $pink;
            font-weight: 700;
        }
    }
}
.nav__menu--footer {
    .nav__list {
        margin: 0;
        justify-content: flex-end
    }
    .nav__link {
        color: $white;
    }
}
.space {
    height: 108px;
}
.active {
    color: $pink;
    font-weight: 700; 
}

@include mq('sm') {
    .nav__menu-hide {
        display: none;
    }
    .space {
        height: 167px; 
    }
    .nav__list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        &>li+li {
            margin-left: 0px;
            margin-top: 10px;
        }
    }
    .header {
        padding-bottom: 10px;
    }

}
@include mq('xs') {
    .header__title {
        font-size: 18px;
    }
    .phone__link {
       font-size: 17px; 
    }
    .header {
        padding-bottom: 5-px;
    }
}
@media (max-width:360px) {
    .header__title {
        font-size: 14px;
    }
}

.line-un {
    text-decoration: underline;
}
@media (max-width: 800px) {
    .nav__link {
        font-size: 13px;
    }
    
}