.section__title {
    font-size: 40px;
    line-height: 44px;
    font-weight: 300;
    color: $pink;

    margin-top: 35px;
}
.section__subtitle--training {
    font-size: 26px;
    line-height: 30px;
    font-weight: 300;
    color: $pink;

    margin-top: 35px;
}
.section__title-white {
    color: $white;
}
.section__subtitle {
    font-size: 18px;
    line-height: 22px;
    color: $pink;
    font-weight: 700;
    margin-top: 32px;
}
.section__subtitle-white {
    color: $white;
}
.section__text {
    margin-top: 20px;
    font-size: 16px;
    line-height: 19px;
    color: $black;
    font-weight: 300;
    &>p+p {
        margin-top: 10px;
    }
}
.section__text-sm {
    font-size: 14px;
    color: $primary;
    font-weight: 400;
}
.line-th {
    text-decoration: line-through;
}
.bold {
    font-weight: 700;
    color: $primary;
}
.section__link {
    color: $pink;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}
.tel__link {
    color: #FF00B6;
}

.img {
    max-width: 100%;
    width: 100%;
}
.img-mt {
    margin-top: 10px;
}