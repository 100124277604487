.yandex__maps {
    margin-top: 20px;
    iframe {
        width: 100%;
        height: 400px;
        border: 1px;
    }
}
.adress__text {
    color: steelblue;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    margin-top: 20px;
}
.img__adress {
    margin-top: 10px;
}

.contacts__wrap {
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.contact__content {
    margin-left: 10px;
}
.contact__write {
    margin-top: 8px;
}

.social__list {
    margin-top: 20px;
    &>li+li {
        margin-top: 10px;
    }
}
.social__item-link {
    list-style: inside;
}
.work__time {
    margin-top: 30px;
}
.work__time-title {
    font-size: 14px;
    line-height: 16px;
}
.work__time-list {
    margin-top: 10px;
    &>li+li {
        margin-top: 7px;
    }
}
.work__time-item{
    list-style: inside;
}

.master__text {
    font-size: 14px;
    color: $primary;
}
.tel__img {
    width: 60px;
    height: 45px;
}