.btn {
  position: relative;
  display: block;
  text-align: right;
  max-width: 163px;
  margin-left: auto;


  font-size: 14px;
  color: $pink;
  border: 1px solid $pink;
  padding: 13px 13px 13px 50px;
  transition: all .4s ease;
  &::before {
    content: "";
    position: absolute;
    left: 20px;
    top: 8px;
    width: 21px;
    height: 26px;
    background: url(../img/vkbtn.png) no-repeat;
  }
  &:hover::before {
    background: url(../img/vkbtn-hov.png) no-repeat;
  }
  &:hover {
    background: $pink;
    color: $white;
  }
  
}
.btn-main {
  text-transform: uppercase;
  margin-top: 30px;
  max-width: 113px;
  text-align: center;
  margin-left: 0;
  padding: 10px;
  &::before {
    display: none;
  }
  
}

.btn-main-center {
  max-width: 113px;
  margin-left: 0;
  margin: 0 auto;
  margin-top: 20px;
}

@include mq ('xs') {
  .hero {
    .btn {
      margin-top: -10px;
    }
  }
  
}